<template>
  <div>
    <div class="box-main">
      <div class="middle" ref="jiamiddle">
        <div class="newjindu" v-if="yincang">
          <div class="newjinduleft">
            <div class="newleft">
              <div class="newqie">
                <span
                  :class="qieindex == 2 ? 'activenewtit' : 'newtit'"
                  @click="newqiebtn(2)"
                  >订单统计</span
                >
                <span
                  :class="qieindex == 1 ? 'activenewtit' : 'newtit'"
                  @click="newqiebtn(1)"
                  >个人统计</span
                >
                <span
                  :class="qieindex == 3 ? 'activenewtit' : 'newtit'"
                  @click="newqiebtn(3)"
                  v-if="showyj"
                  >业务员业绩</span
                >
                <div class="yewubox" v-show="yewuyin">
                  <div class="yewumain2">
                    <div class="yewuboxleft">
                      <span class="yewutit">产值数据</span>
                    </div>
                    <div class="yewubiao" ref="yewuchart"></div>
                  </div>
                  <div class="yewumain">
                    <div class="yewuboxleft">
                      <span class="yewutit">业绩明细表</span>
                      <span class="yewutxt">当前实际产值截止昨日</span>
                    </div>
                  </div>
                  <div class="yewutab">
                    <el-table
                      :data="yewutableData"
                      width="100%"
                      height="100%"
                    >
                      <el-table-column prop="name" label="姓名">
                      </el-table-column>
                      <el-table-column label="个人目标产值(元)">
                        <el-table-column prop="need" label="本月">
                        </el-table-column>
                        <el-table-column prop="need1" label="上月">
                          <template #default="scope">
                            <span :class="scope.row.need1 == 0 ? 'yetit2' : scope.row.need1 > 0 ? 'yetit' : 'yetxt'">{{ scope.row.need1 }}</span>
                          </template>
                        </el-table-column>
                      </el-table-column>
                      <el-table-column label="实际产值(元)">
                        <el-table-column prop="now" label="本月">
                        </el-table-column>
                        <el-table-column prop="now1" label="上月">
                          <template #default="scope">
                            <span :class="scope.row.now1 == 0 ? 'yetit2' : scope.row.now1 > 0 ? 'yetit' : 'yetxt'">{{ scope.row.now1 }}</span>
                          </template>
                        </el-table-column>
                      </el-table-column>
                      <el-table-column label="产值完成率(%)">
                        <el-table-column prop="finish" label="本月">
                        </el-table-column>
                        <el-table-column prop="finish1" label="上月">
                          <template #default="scope">
                            <span :class="scope.row.finish1 == 0 ? 'yetit2' : scope.row.finish1 > 0 ? 'yetit' : 'yetxt'">{{ scope.row.finish1 }}</span>
                          </template>
                        </el-table-column>
                      </el-table-column>
                      <el-table-column label="订单总数(单)">
                        <el-table-column prop="orderNum" label="本月">
                        </el-table-column>
                        <el-table-column prop="orderNum1" label="上月">
                          <template #default="scope">
                            <span :class="scope.row.orderNum1 == 0 ? 'yetit2' : scope.row.orderNum1 > 0 ? 'yetit' : 'yetxt'">{{ scope.row.orderNum1 }}</span>
                          </template>
                        </el-table-column>
                      </el-table-column>
                      <el-table-column label="合作商下单量">
                        <el-table-column prop="xcx" label="本月(占比)">
                        </el-table-column>
                        <el-table-column prop="xcxNum1" label="上月">
                          <template #default="scope">
                            <span :class="scope.row.xcxNum1 == 0 ? 'yetit2' : scope.row.xcxNum1 > 0 ? 'yetit' : 'yetxt'">{{ scope.row.xcxNum1 }}</span>
                          </template>
                        </el-table-column>
                      </el-table-column>
                      <el-table-column label="新增合作商(个)">
                        <el-table-column prop="addNum" label="本月">
                        </el-table-column>
                        <el-table-column prop="addNum1" label="上月">
                          <template #default="scope">
                            <span :class="scope.row.addNum1 == 0 ? 'yetit2' : scope.row.amoaddNum1unt3 > 0 ? 'yetit' : 'yetxt'">{{ scope.row.addNum1 }}</span>
                          </template>
                        </el-table-column>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
              <!-- 订单统计 -->
              <div class="newxuan" v-if="qieindex == 2">
                <i
                  @click="newday(1)"
                  :class="[newdaycolor == 1 ? 'newxuanzi' : 'newzi']"
                  >今天</i
                >
                <i
                  @click="newday(2)"
                  :class="[newdaycolor == 2 ? 'newxuanzi' : 'newzi']"
                  >昨天</i
                >
                <i
                  @click="newday(3)"
                  :class="[newdaycolor == 3 ? 'newxuanzi' : 'newzi']"
                  >当月</i
                >
              </div>
              <!-- 个人统计 -->
              <div class="newxuan" v-if="qieindex == 1">
                <i
                  @click="grnewday(1)"
                  :class="[grnewdaycolor == 1 ? 'newxuanzi' : 'newzi']"
                  >今天</i
                >
                <i
                  @click="grnewday(2)"
                  :class="[grnewdaycolor == 2 ? 'newxuanzi' : 'newzi']"
                  >昨天</i
                >
                <i
                  @click="grnewday(3)"
                  :class="[grnewdaycolor == 3 ? 'newxuanzi' : 'newzi']"
                  >当月</i
                >
              </div>
            </div>
            <!-- 个人数据 -->
            <div class="newleftmain" v-if="qieindex == 1">
              <!-- 下单量 -->
              <div class="grimmediate">
                <img class="grbeimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="grimmediatetxt">下单量</span>
                <div class="grimmediatenum">
                  {{ a1 }}
                  <i class="grimmediateadd">第{{ a2 }}名</i>
                </div>
                <el-progress
                  :percentage="grxiadan"
                  :show-text="false"
                  color="#fff"
                />
                <div class="grjishibottom">
                  <div class="immediatetit">已完成:{{ a3 }}</div>
                  <div class="immediatetit">{{ a4 }}%</div>
                </div>
              </div>
              <!-- 派单量 -->
              <div class="grimmediate6">
                <img class="grbeimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="grimmediatetxt6">派单量</span>
                <div class="grimmediatenum">
                  {{ b1 }}
                  <i class="grimmediateadd">第{{ b2 }}名</i>
                </div>
                <el-progress
                  :percentage="grpaidan"
                  :show-text="false"
                  color="#fff"
                />
                <div class="grjishibottom">
                  <div class="immediatetit">已完成:{{ b3 }}</div>
                  <div class="immediatetit">{{ b4 }}%</div>
                </div>
              </div>
              <!-- 取消量 -->
              <div class="grimmediate2">
                <img class="grbeimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="grimmediatetxt2">取消量</span>
                <div class="grimmediatenum">
                  {{ c1 }}
                  <i class="grimmediateadd">{{ c2 }}%</i>
                </div>
                <el-progress
                  :percentage="grquxiao"
                  :show-text="false"
                  color="#fff"
                />
                <div class="grjishibottom">
                  <div class="immediatetit">有费:{{ c3 }}</div>
                  <div class="immediatetit">失败:{{ c4 }}</div>
                </div>
              </div>
              <!-- 通话量 -->
              <div class="grimmediate7">
                <img class="grbeimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="grimmediatetxt7">通话量</span>
                <div class="grimmediatenum">
                  {{ d1 }}
                  <i class="grimmediateadd">{{ d2 }}%</i>
                </div>
                <el-progress
                  :percentage="grtonghua"
                  :show-text="false"
                  color="#fff"
                />
                <div class="grjishibottom">
                  <div class="immediatetit">呼入:{{ d3 }}</div>
                  <div class="immediatetit">呼出:{{ d4 }}</div>
                </div>
              </div>
              <!-- 照片审核 -->
              <div class="grimmediate3">
                <img class="grbeimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="grimmediatetxt3">照片审核</span>
                <div class="grimmediatenum">
                  {{ e1 }}
                  <i class="grimmediateadd">应审</i>
                </div>
                <el-progress
                  :percentage="grshenhe"
                  :show-text="false"
                  color="#fff"
                />
                <div class="grjishibottom">
                  <div class="immediatetit">已审</div>
                  <div class="immediatetit">{{ e2 }}</div>
                </div>
              </div>
              <!-- 保险时效 -->
              <div class="grimmediate4">
                <img class="grbeimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="grimmediatetxt4">保险时效</span>
                <div class="grimmediatenum">
                  {{ f1 }}
                  <i class="grimmediateadd">受理时间</i>
                </div>
                <el-progress
                  :percentage="grbaoxian"
                  :show-text="false"
                  color="#fff"
                />
                <div class="grjishibottom">
                  <div class="immediatetit">指派时间</div>
                  <div class="immediatetit">{{ f2 }}</div>
                </div>
              </div>
              <!-- 回访 -->
              <div class="grimmediate5">
                <img class="grbeimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="grimmediatetxt5">回访</span>
                <div class="grimmediatenum">
                  {{ g1 }}
                  <i class="grimmediateadd">不满意</i>
                </div>
                <el-progress
                  :percentage="grhuifang"
                  :show-text="false"
                  color="#fff"
                />
                <div class="grjishibottom">
                  <div class="immediatetit">回访率:{{ g2 }}%</div>
                  <div class="immediatetit">投诉:{{ g3 }}</div>
                </div>
              </div>
            </div>
            <!-- 订单统计 -->
            <div class="newleftmain" v-if="qieindex == 2">
              <!-- 即时单数 -->
              <div class="immediate">
                <img class="beimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="immediatetxt">即时单</span>
                <div class="immediatenum">
                  {{ jstotal }}
                  <i class="immediateadd" v-if="this.jscompare > 0"
                    >+{{ jscompare }}</i
                  >
                  <i class="immediateadd" v-if="this.jscompare < 0">{{
                    jscompare
                  }}</i>
                </div>
                <el-progress
                  :percentage="jishipercentage"
                  :show-text="false"
                  color="#fff"
                />
                <div class="jishibottom">
                  <div class="immediatetit">已完成:{{ jstotal1 }}</div>
                  <div class="immediatetit">未完成:{{ jstotal2 }}</div>
                </div>
              </div>
              <!-- 预约单数 -->
              <div class="immediate1">
                <img class="beimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="immediatetxt1">预约单</span>
                <div class="immediatenum">
                  {{ yytotal }}
                  <i class="immediateadd" v-if="this.yycompare > 0"
                    >+{{ yycompare }}</i
                  >
                  <i class="immediateadd" v-if="this.yycompare < 0">{{
                    yycompare
                  }}</i>
                </div>
                <el-progress
                  :percentage="yuyuepercentage"
                  :show-text="false"
                  color="#fff"
                />
                <div class="jishibottom">
                  <div class="immediatetit">已完成:{{ yytotal1 }}</div>
                  <div class="immediatetit">未完成:{{ yytotal2 }}</div>
                </div>
              </div>
              <!-- 顺路单数 -->
              <div class="immediate2">
                <img class="beimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="immediatetxt2">顺路单</span>
                <div class="immediatenum">
                  {{ sltotal }}
                  <i class="immediateadd" v-if="this.slcompare > 0"
                    >+{{ slcompare }}</i
                  >
                  <i class="immediateadd" v-if="this.slcompare < 0">{{
                    slcompare
                  }}</i>
                </div>
                <el-progress
                  :percentage="shunlupercentage"
                  :show-text="false"
                  color="#fff"
                />
                <div class="jishibottom">
                  <div class="immediatetit">已完成:{{ sltotal1 }}</div>
                  <div class="immediatetit">未完成:{{ sltotal2 }}</div>
                </div>
              </div>
              <!-- 取消单数 -->
              <div class="immediate3">
                <img class="beimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="immediatetxt3">取消单</span>
                <div class="immediatenum">
                  <div style="width:40%;float:left;align-items: center;display:flex;">
                      {{ qxtotal }}
                    <i class="immediateadd" v-if="this.qxcompare > 0" 
                      >+{{ qxcompare }}</i
                    >
                    <i class="immediateadd" v-if="this.qxcompare < 0">{{
                      qxcompare
                    }}</i>
                  </div>
                  <div style="width:60%;float:right; font-size:13px; text-align: right;">
                    救援单：{{ qxtotalj }}
                  </div>

                </div>
                <el-progress
                  :percentage="quxiaopercentage"
                  :show-text="false"
                  color="#fff"
                />
                <div class="jishibottom">
                  <div class="immediatetit">有费:{{ qxtotal1 }}</div>
                  <div class="immediatetit">无费:{{ qxtotal3 }}</div>
                  <div class="immediatetit">失败:{{ qxtotal2 }}</div>
                </div>
              </div>
              <!-- 总订单数 -->
              <div class="immediate4">
                <img class="beimg" src="../assets/img/cd/beimg.png" alt="" />
                <span class="immediatetxt4">总订单</span>
                <div class="immediatenum">
                  {{ alltotal }}
                  <i class="immediateadd" v-if="this.totalcompare > 0"
                    >+{{ totalcompare }}</i
                  >
                  <i class="immediateadd" v-if="this.totalcompare < 0">{{
                    totalcompare
                  }}</i>
                </div>
                <el-progress
                  :percentage="allpercentage"
                  :show-text="false"
                  color="#fff"
                />
                <div class="jishibottom">
                  <div class="immediatetit">已完成:{{ alltotal1 }}</div>
                  <div class="immediatetit">未完成:{{ alltotal2 }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="newjinduright">
            <div class="allnav">
              <div class="allnavleft">
                <img
                  class="allimg"
                  src="../assets/img/index/workbench-icon-abnormal.png"
                  alt=""
                />
                订单异常
              </div>
              <div class="zongnum">{{ zongnum }}</div>
            </div>
            <div class="totalorder">
              <div
                class="totalorderblock"
                v-for="(item, index) in totallist"
                :key="index"
                @click="abnormal(item.type, item.name, item.num)"
              >
                <span
                  :class="item.num == 0 ? 'totalordernum' : 'totalorderactive'"
                  >{{ item.num }}</span
                >
                <span class="totalordertit">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="main" ref="newmain">
          <div class="main-nav">
            <div class="newhead">
              <div class="danbox">
                <div class="danboxleft">
                  <div
                    class="newbtn dabei"
                    v-if="yincang == true"
                    @click="reset"
                  >
                    <img
                      class="newzhi"
                      src="../assets/img/index/dajia.png"
                      alt=""
                    />
                    <button class="reset2"></button>
                  </div>
                  <div
                    class="newbtn dabei"
                    v-if="yincang == false"
                    @click="reset2"
                  >
                    <img
                      class="newzhi"
                      src="../assets/img/index/dajian.png"
                      alt=""
                    />
                    <button class="reset2"></button>
                  </div>
                  <div class="newbtn" v-if="xuanzhong">
                    <!-- <img
                    class="newzhi"
                    src="../assets/img/index/chongzhi.png"
                    alt=""
                  /> -->
                    <button class="reset1" @click="huifu">全部</button>
                  </div>
                  <div class="newbtn2" v-if="weixuan">
                    <button class="reset2" @click="huifu">全部</button>
                  </div>
                  <i
                    @click="typedan(1)"
                    :class="[typedanclorl === 1 ? 'jishistyle2' : 'jishistyle']"
                    >即时</i
                  >
                  <i
                    @click="typedan(2)"
                    :class="[typedanclorl === 2 ? 'yuyuestyle2' : 'yuyuestyle']"
                    >预约</i
                  >
                  <i
                    @click="typedan(3)"
                    :class="[
                      typedanclorl === 3 ? 'shunlustyle2' : 'shunlustyle',
                    ]"
                    >顺路</i
                  >
                </div>

                <div class="switch">
                  <div class="pingtai">
                    平台流转
                    <el-switch
                      v-model="pingtaivalue"
                      active-color="#2c68ff"
                      inactive-color="#9ea3b9"
                      @change="plat"
                    >
                    </el-switch>
                  </div>
                  <div class="pingtai">
                    拆分订单
                    <el-switch
                      v-model="chaivalue"
                      active-color="#2c68ff"
                      inactive-color="#9ea3b9"
                      @change="chai"
                    >
                    </el-switch>
                  </div>
                  <div class="pingtai">
                    共享订单
                    <el-switch
                      v-model="xiangvalue"
                      active-color="#2c68ff"
                      inactive-color="#9ea3b9"
                      @change="gongxiang"
                    >
                    </el-switch>
                  </div>
                </div>
              </div>

              <div class="danbox">
                <ul class="state">
                  <li class="nav-title">
                    <a
                      @click="adds(1)"
                      :class="{ active: add === 1 }"
                      class="one"
                    >
                      <i :class="num1 == 0 ? 'newactive' : 'newtabnum'">{{
                        num1
                      }}</i>
                      已受理
                    </a>
                  </li>
                  <li class="nav-title">
                    <a
                      @click="adds(2)"
                      :class="{ active: add === 2 }"
                      class="one"
                    >
                      <i :class="num2 == 0 ? 'newactive' : 'newtabnum'">{{
                        num2
                      }}</i>
                      已调度
                    </a>
                  </li>
                  <li class="nav-title">
                    <a @click="adds(13)" :class="{ active: add === 13 }">
                      <i :class="num13 == 0 ? 'newactive' : 'newtabnum'">{{
                        num13
                      }}</i>
                      已接单
                    </a>
                  </li>
                  <li class="nav-title">
                    <a @click="adds(11)" :class="{ active: add === 11 }">
                      <i :class="num11 == 0 ? 'newactive' : 'newtabnum'">{{
                        num11
                      }}</i>
                      已联系
                    </a>
                  </li>
                  <li class="nav-title">
                    <a @click="adds(12)" :class="{ active: add === 12 }">
                      <i :class="num12 == 0 ? 'newactive' : 'newtabnum'">{{
                        num12
                      }}</i>
                      已出发
                    </a>
                  </li>
                  <li class="nav-title">
                    <a @click="adds(4)" :class="{ active: add === 4 }">
                      <i :class="num4 == 0 ? 'newactive' : 'newtabnum'">{{
                        num4
                      }}</i>
                      任务点
                    </a>
                  </li>
                  <li class="nav-title">
                    <a @click="adds(5)" :class="{ active: add === 5 }">
                      <i :class="num5 == 0 ? 'newactive' : 'newtabnum'">{{
                        num5
                      }}</i>
                      服务中
                    </a>
                  </li>
                  <li class="nav-title">
                    <a @click="adds(6)" :class="{ active: add === 6 }">
                      <i :class="num6 == 0 ? 'newactive' : 'newtabnum'">{{
                        num6
                      }}</i>
                      目的地
                    </a>
                  </li>
                  <li class="nav-title">
                    <a @click="adds(7)" :class="{ active: add === 7 }">
                      <i :class="num7 == 0 ? 'newactive' : 'newtabnum'">{{
                        num7
                      }}</i>
                      已完成
                    </a>
                  </li>
                  <li class="nav-title">
                    <a @click="adds(8)" :class="{ active: add === 8 }">
                      <i :class="num8 == 0 ? 'newactive' : 'newtabnum'">{{
                        num8
                      }}</i>
                      救援失败
                    </a>
                  </li>
                  <li class="nav-title">
                    <a @click="adds(9)" :class="{ active: add === 9 }">
                      <i :class="num9 == 0 ? 'newactive' : 'newtabnum'">{{
                        num9
                      }}</i>
                      取消无费
                    </a>
                  </li>
                  <li class="nav-title">
                    <a
                      @click="adds(0)"
                      :class="{ active: add === 0 }"
                      class="one"
                    >
                      <i :class="num0 == 0 ? 'newactive' : 'newtabnum'">{{
                        num0
                      }}</i>
                      全部
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="newheadbom">
              <div class="newtime">
                <el-form ref="form">
                  <el-form-item label="救援时间">
                    <el-date-picker
                      class="yuTime"
                      v-model="value1"
                      type="datetime"
                      placeholder="请选择时间"
                      size="default"
                    >
                    </el-date-picker>
                    <el-date-picker
                      class="yuTime"
                      v-model="value2"
                      type="datetime"
                      placeholder="请选择时间"
                      size="default"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-form>
              </div>
              <div class="newsou">
                <el-form ref="form">
                  <el-col :span="11">
                    <el-select filterable v-model="tech" placeholder="全部技师">
                      <el-option
                        v-for="item1 in techlist"
                        :key="item1.uid"
                        :value="item1.uid"
                        :label="item1.nickname"
                      >
                        {{ item1.nickname }}
                      </el-option>
                    </el-select>
                  </el-col>
                </el-form>
                <el-form ref="form">
                  <el-col :span="11">
                    <el-select
                      filterable
                      v-model="saleman"
                      placeholder="全部业务员"
                    >
                      <el-option
                        v-for="item1 in saleslist"
                        :key="item1.uid"
                        :value="item1.uid"
                        :label="item1.nickname"
                      >
                        {{ item1.nickname }}
                      </el-option>
                    </el-select>
                  </el-col>
                </el-form>
                <el-select filterable v-model="city" placeholder="区域选择">
                  <el-option
                    v-for="item in quyulist"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  >
                  </el-option>
                </el-select>
                <el-select
                  filterable
                  v-model="county"
                  placeholder="区域选择"
                  v-if="city > 0"
                >
                  <el-option
                    v-for="item in countylist"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  >
                  </el-option>
                </el-select>
                <div class="seek">
                  <el-select
                    filterable
                    v-model="keytype"
                    placeholder="下拉选择"
                  >
                    <el-option
                      v-for="item in sousuo"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    >
                    </el-option>
                  </el-select>
                  <input
                    class="sekint"
                    type="text"
                    @keyup.enter="keyword1"
                    v-model="keyword"
                    placeholder="输入关键字"
                  />

                  <div class="glass">
                    <img
                      class="icon"
                      src="../assets/img/index/icon-search.png"
                      alt=""
                      @click="keyword1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="jinXing" ref="newjinxing">
            <el-table :data="list" stripe="true" height="90%" width="100%">
              <el-table-column prop="rescueId" label="状态" width="150">
                <template #default="scope">
                  <div class="taibox">
                    <div class="taitu" @click="group(scope.row.rescueId)">
                      <img class="li-img" :src="scope.row.svg2" alt="" />
                      <span
                        class="zhuang"
                        :style="{ background: scope.row.color }"
                        >{{ scope.row.statusType }}</span
                      >
                    </div>
                    <div>
                      <img
                        class="splitimg"
                        src="../assets/img/cd/cx.png"
                        alt=""
                        v-if="scope.row.isShare >= 1 && scope.row.isOpen >= 1"
                      />
                      <img
                        class="splitimg"
                        src="../assets/img/cd/split.png"
                        alt=""
                        v-else-if="scope.row.isOpen >= 1"
                      />
                      <img
                        class="splitimg"
                        src="../assets/img/cd/gongxiang.png"
                        alt=""
                        v-else-if="scope.row.isShare >= 1"
                      />
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="订单类型" width="80">
                <template #default="scope">
                  <span class="take1" v-if="scope.row.rescueStyle == '即时单'"
                    >即时单</span
                  >
                  <span class="take2" v-if="scope.row.rescueStyle == '预约单'"
                    >预约单</span
                  >
                  <span class="take3" v-if="scope.row.rescueStyle == '顺路单'"
                    >顺路单</span
                  >
                </template>
              </el-table-column>

              <el-table-column width="120">
                <template #header>
                  <span class="paixustyle" @click="paixu" v-if="yinshengxu"
                    >救援时间</span
                  >
                  <span class="paixustyle" @click="paixu2" v-if="yinjiangxu"
                    >救援时间</span
                  >
                </template>

                <template #default="scope">
                  {{ scope.row.apTime }}
                </template>
              </el-table-column>

              <el-table-column label="合作商简称" width="150">
                <template #default="scope">
                  {{ scope.row.fromname }}
                </template>
              </el-table-column>

              <el-table-column
                label="位置"
                :width="flexColumnWidth(list, 'max', 'address', 'mudi')"
              >
                <template #default="scope">
                  {{ scope.row.address }}
                  <br />
                  {{ scope.row.mudi }}
                </template>
              </el-table-column>

              <el-table-column
                label="协作商信息"
                :width="flexColumnWidth(list, 'max', 'newxiezuo', 'xinxi')"
              >
                <template #default="scope">
                  {{ scope.row.newxiezuo }}
                  <br />
                  <div class="carcall">
                    {{ scope.row.xinxi }}
                    <button
                      @click="call(scope.row.takemobile, scope.row.rescueId)"
                      class="call"
                      v-show="emicreg != '' && scope.row.takemobile != ''"
                    ></button>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="订单来源" width="120">
                <template #default="scope">
                  {{ scope.row.fromtype }}
                </template>
              </el-table-column>

              <el-table-column label="收费类型" width="120">
                <template #default="scope">
                  {{ scope.row.shoufei }}
                </template>
              </el-table-column>

              <el-table-column label="车主信息" width="150">
                <template #default="scope">
                  {{ scope.row.rescueName }}/{{ scope.row.carpai }}
                  <br />
                  <div class="carcall">
                    {{ scope.row.rescueMobile }}
                    <button
                      @click="call(scope.row.rescueMobile, scope.row.rescueId)"
                      class="call"
                      v-show="emicreg != ''"
                    ></button>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="业务员" width="120">
                <template #default="scope">
                  {{ scope.row.saleman }}
                  <br />
                  <div class="carcall">
                    {{ scope.row.salemanmobile }}
                    <button
                      @click="call(scope.row.salemanmobile, scope.row.rescueId)"
                      class="call"
                      v-show="emicreg != ''"
                    ></button>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="编号"
                :width="flexColumnWidth(list, 'max', 'rescueId')"
              >
                <template #default="scope">
                  #{{ scope.row.rescueId }}
                </template>
              </el-table-column>

              <el-table-column fixed="right" label="操作">
                <template #default="scope">
                  <el-button
                    link
                    type="primary"
                    size="small"
                    @click="lish(scope.row.rescueId)"
                    v-show="scope.row.canCancel && !getLook"
                  >
                    取消
                  </el-button>
                  <el-button
                    link
                    type="primary"
                    size="small"
                    disabled
                    style="background: #bcbcbc; border: 0"
                    v-show="!scope.row.canCancel || getLook"
                  >
                    取消
                  </el-button>

                  <el-button
                    link
                    type="primary"
                    size="small"
                    @click="diaodu(scope.row.rescueId)"
                    v-show="scope.row.canAssign && !getLook"
                  >
                    指派
                  </el-button>

                  <el-button
                    link
                    type="primary"
                    size="small"
                    @click="diaodu(scope.row.rescueId)"
                    v-show="scope.row.canRssign && !getLook"
                  >
                    改派
                  </el-button>

                  <el-button
                    link
                    type="primary"
                    size="small"
                    disabled
                    style="background: #bcbcbc; border: 0"
                    v-if="
                      (!scope.row.canRssign && !scope.row.canAssign) || getLook
                    "
                  >
                    改派
                  </el-button>
                  <el-button
                    link
                    type="primary"
                    size="small"
                    @click="copyr(scope.row.rescueId)"
                    v-show="scope.row.canCopy && !getLook"
                  >
                    复制
                  </el-button>
                  <el-button
                    link
                    type="primary"
                    size="small"
                    disabled
                    style="background: #bcbcbc; border: 0"
                    v-show="!scope.row.canCopy || getLook"
                  >
                    复制
                  </el-button>
                  <el-button
                    link
                    type="primary"
                    size="small"
                    @click="match(scope.row.rescueId)"
                    v-show="scope.row.canMatchnew && !getLook"
                  >
                    匹配
                  </el-button>
                  <el-button
                    link
                    type="primary"
                    size="small"
                    disabled
                    style="background: #bcbcbc; border: 0"
                    v-show="!scope.row.canMatchnew || getLook"
                  >
                    匹配
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <ding-gai :isshows="alters" :diaodurescueid="diaoduid"></ding-gai>
    <match :matches="ismatches" :matchrescueid="matchid"></match>
    <copy :iscopyset="copyset" :copyid="copyrescueid"></copy>
    <canael :iscar="car" :rescueIds="rescueIdw"></canael>
    <routine v-show="route"></routine>
    <part :islook="look" :id="rescueIdw" @checkbtn="checkbtn"></part>
    <check
      :isaudio="audio"
      :rescueIdcheck="rescueId"
      @checksx="sx"
      @partbtn="partbtn"
    ></check>
    <unusual
      :isyichang="yichang"
      @guanyichang="guanyichang"
      :yitype="yichangtype"
      :yiname="yiname"
      :yinum="yinum"
    ></unusual>
  </div>
</template>

<script>
import check from "../components/check.vue";
import Canael from "../components/canael.vue";
import DingGai from "../components/dingGai.vue";
import Routine from "../components/routine.vue";
import unusual from "../components/unusual.vue";
import copy from "../components/copy.vue";
import Cookies from "js-cookie";
import phonebar from "jssip-emicnet/dist/phonebar";
import part from "../components/part.vue";
import match from "../components/match.vue";
import * as api from "../api/index";
import {
  sendSock,
  createWebSocket,
  closeSock,
  closeSockS,
} from "../api/websockwork.js";
import * as ola from "../api/olawebsock.js";
import * as vt from "../api/verto.js";
import "../assets/iconfont/iconfont.css";
import Vue from "vue";
// Symbol
import "../assets/iconfont/iconfont.js";
const echarts = require("echarts");
export default {
  components: {
    check,
    unusual,
    DingGai,
    Canael,
    Routine,
    part,
    copy,
    match,
  },
  data() {
    return {
      pingtaivalue: false,
      chaivalue: false,
      xiangvalue: false,
      yichang: 0,
      yichangtype: "",
      yiname: "",
      yinum: "",
      add: 1,
      typedanclorl: 0,
      isShow: 0,
      alters: 0,
      diaoduid: "",
      xiang: "",
      mudiValue: "",
      car: 0,
      route: false,
      list: [],
      look: 0,
      rescueIdw: "",
      // 当前页数
      pagesize: 1,
      // 今日/本周/星标 选中态
      timescolor: 1,
      totalpage: 100,
      datas: {
        type: 1,
        page: 1,
        perpage: 100,
        keytype: "",
        keyword: null,
        rescueStyle: 0,
        createStart: "",
        createEnd: "",
        city: "",
        county: "",
        tech: "",
        saleman: "",
        platshow: 0,
        isOpen: 0,
        isShare: 0,
      },
      toptotal: 0,
      topshow1: 0,
      topshow2: 0,
      topshow3: 0,
      emicreg: this.$store.state.emic,
      keytype: "",
      keyword: "",
      timer: null,
      isChange: true,
      sousuo: [
        {
          value: "1",
          label: "订单号",
        },
        {
          value: "2",
          label: "车主信息",
        },
        {
          value: "3",
          label: "技师信息",
        },
        {
          value: "4",
          label: "任务点",
        },
        {
          value: "5",
          label: "目的地",
        },
        {
          value: "6",
          label: "合作商",
        },
        {
          value: "7",
          label: "案件号",
        },
      ],
      typedancolor: 0,
      jishi: [],
      yutishi: -1,
      copyrescueid: "",
      copyset: 0,

      newdaycolor: 1,
      alltotal: "",
      alltotal1: "",
      alltotal2: "",
      jstotal: "",
      jstotal1: "",
      jstotal2: "",
      jscompare: "",
      yytotal: "",
      yytotal1: "",
      yytotal2: "",
      yycompare: "",
      sltotal: "",
      sltotal1: "",
      sltotal2: "",
      slcompare: "",
      qxtotal: "",
      qxtotal1: "",
      qxtotal2: "",
      qxtotal3: "0",
      qxtotalj: "100",
      qxcompare: "",
      totalcompare: "",
      newchuang: "",
      newyue: "",
      city: "",
      county: "",
      quyulist: [],
      countylist: [],
      yincang: true,
      num0: 0,
      num1: 0,
      num2: 0,
      num3: 0,
      num4: 0,
      num5: 0,
      num6: 0,
      num7: 0,
      num8: 0,
      num9: 0,
      num11:0,
      num12:0,
      num13:0,

      yinshengxu: true,
      yinjiangxu: false,
      xuanzhong: true,
      weixuan: false,
      value1: new Date(new Date().setHours(0, 0, 0, 0)),
      value2: new Date(new Date().setHours(0, 0, 0, 0) + 3600 * 1000 * 24),
      tech: "",
      techlist: [],
      saleman: "",
      saleslist: [],
      platshow: 0,
      isOpen: 0,
      isShare: 0,

      audio: 0,
      rescueId: "",
      matchid: "",
      ismatches: 0,

      totallist: [],
      zongnum: "",
      jishipercentage: 0,
      yuyuepercentage: 0,
      shunlupercentage: 0,
      quxiaopercentage: 0,
      allpercentage: 0,

      timerwork: null,
      timerun: null,
      listtimer: null,
      listimer2: null,
      timernum: 0,
      timernum2: 0,

      qieindex: 2,
      grnewdaycolor: 3,
      a1: 0,
      a2: 0,
      a3: 0,
      a4: 0,
      b1: 0,
      b2: 0,
      b3: 0,
      b4: 0,
      c1: 0,
      c2: 0,
      c3: 0,
      c4: 0,
      d1: 0,
      d2: 0,
      d3: 0,
      d4: 0,
      e1: 0,
      e2: 0,
      f1: 0,
      f2: 0,
      g1: 0,
      g2: 0,
      g3: 0,
      showyj:false,
      yewutableData: [
      ],
      yewuyin: false,
      xaxis:[],
      xline1:[],
      xline2:[],
      KeySearch:true,
      timeroutsearch:'',

      grxiadan:0,
      grpaidan:0,
      grquxiao:0,
    };
  },
  async created() {
    this.res_workOrders(this.datas);
    this.initWebSocket();
    this.res_Statistics(1);
    this.getcity();

    this.getUnusual();

    let that = this;

    if (this.listtimer == null) {
      this.listtimer = setInterval(function () {
        that.timerclock();
      }, 60000);
      console.log('加载定时器1')
    }

    if (this.listtimer2 == null) {
      this.listtimer2 = setInterval(function () {
        that.res_Statistics(1);
      }, 1800000);
      console.log('加载定时器2')
    }
  },
  beforeUnmount() {
    if (this.listtimer) {
      clearInterval(this.listtimer);
      console.log('消除定时器1')
    }

    if (this.listtimer2) {
      clearInterval(this.listtimer2);
      console.log('消除定时器2')
    }
  },

  methods: {
    yewuchart() {
      let minChart = echarts.init(this.$refs.yewuchart);
      minChart.setOption({
        // title: {
        //   text: "产值数据",
        // },
        tooltip: {
          trigger: "axis",
        },

        legend: {
          data: ["目标产值", "实际完成产值"],
        },
        color:['#2c68ff','red'],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },保存为图片
        xAxis: {
          name: "月份",
          type: "category",
          boundaryGap: false,
          data: this.xaxis,
        },
        yAxis: {
          type: "value",
          name: "金额",
        },
        series: [
          {
            name: "目标产值",
            type: "line",
            smooth: true,
            data: this.xline1,
          },
          {
            name: "实际完成产值",
            type: "line",
            smooth: true,
            data:  this.xline2,
          },
        ],
      });
    },
    newqiebtn(e) {
      console.log(e);
      if (e == 1) {
        this.res_StatisticsSelf(this.grnewdaycolor);
        this.yewuyin = false;
      } else if(e == 2) {
        this.res_Statistics(this.newdaycolor);
        this.yewuyin = false;
      }else if (e == 3)
      {
        //console.log(1111);
        this.getsalesdetail();
      }
      this.qieindex = e;

    },
    async getsalesdetail()
    {
      let res = await api.getsales();
      console.log(res);
      this.yewutableData = res.data;
      this.xaxis = res.xline;
      this.xline1 = res.line1;
      this.xline2 = res.line2;
      this.yewuchart();
      this.yewuyin = true;
    },
    grnewday(e) {
      this.res_StatisticsSelf(e);
      this.grnewdaycolor = e;
    },
    abnormal(type, name, num) {
      this.yichang++;
      if (this.yichang == 3) {
        this.yichang = 1;
      }
      this.yichangtype = type;
      this.yiname = name;
      this.yinum = num;
    },
    guanyichang() {
      this.yichang = false;
    },
    async getUnusual() {
      let res_workUnusual = await api.newworkUnusual();
      this.totallist = res_workUnusual.data.column;
      this.zongnum = res_workUnusual.data.numall;
    },
    async res_workOrders(data) {
      let res_workOrder = await api.workOrder(data);
      this.list = res_workOrder.data;

      this.list = this.list.map((item) => {
        let newzuo = item.takeSp;
        let newxie = item.xiezuo;
        let newxiezuo =
          newzuo +
          (newzuo !== "" ? "/" : "" || newxie !== "" ? "/" : "") +
          newxie;
        return {
          ...item,
          newxiezuo: newxiezuo,
        };
      });
      this.num0 = res_workOrder.num0;
      this.num1 = res_workOrder.num1;
      this.num2 = res_workOrder.num2;
      this.num3 = res_workOrder.num3;
      this.num4 = res_workOrder.num4;
      this.num5 = res_workOrder.num5;
      this.num6 = res_workOrder.num6;
      this.num7 = res_workOrder.num7;
      this.num8 = res_workOrder.num8;
      this.num9 = res_workOrder.num9;
      this.num11 = res_workOrder.num11;
      this.num12 = res_workOrder.num12;
      this.num13 = res_workOrder.num13;
      this.totalpage = parseInt(res_workOrder.total);
    },
    async res_workOrdersKeyWord(data) {
      //this.KeySearch = false;
      let res_workOrder = await api.workOrder(data);
      this.list = res_workOrder.data;

      this.list = this.list.map((item) => {
        let newzuo = item.takeSp;
        let newxie = item.xiezuo;
        let newxiezuo =
          newzuo +
          (newzuo !== "" ? "/" : "" || newxie !== "" ? "/" : "") +
          newxie;
        return {
          ...item,
          newxiezuo: newxiezuo,
        };
      });
      this.num0 = res_workOrder.num0;
      this.num1 = res_workOrder.num1;
      this.num2 = res_workOrder.num2;
      this.num3 = res_workOrder.num3;
      this.num4 = res_workOrder.num4;
      this.num5 = res_workOrder.num5;
      this.num6 = res_workOrder.num6;
      this.num7 = res_workOrder.num7;
      this.num8 = res_workOrder.num8;
      this.num9 = res_workOrder.num9;
      this.num11 = res_workOrder.num11;
      this.num12 = res_workOrder.num12;
      this.num13 = res_workOrder.num13;
      this.totalpage = parseInt(res_workOrder.total);
      console.log('关键字查询完结')
      this.KeySearch = true;
    },

    async res_workOrdersSx(data) {
      let res_workOrder = await api.workOrderSx(data);
      this.list = res_workOrder.data;

      this.list = this.list.map((item) => {
        let newzuo = item.takeSp;
        let newxie = item.xiezuo;
        let newxiezuo =
          newzuo +
          (newzuo !== "" ? "/" : "" || newxie !== "" ? "/" : "") +
          newxie;
        return {
          ...item,
          newxiezuo: newxiezuo,
        };
      });
      this.num0 = res_workOrder.num0;
      this.num1 = res_workOrder.num1;
      this.num2 = res_workOrder.num2;
      this.num3 = res_workOrder.num3;
      this.num4 = res_workOrder.num4;
      this.num5 = res_workOrder.num5;
      this.num6 = res_workOrder.num6;
      this.num7 = res_workOrder.num7;
      this.num8 = res_workOrder.num8;
      this.num9 = res_workOrder.num9;
      this.num11 = res_workOrder.num11;
      this.num12 = res_workOrder.num12;
      this.num13 = res_workOrder.num13;
      this.totalpage = parseInt(res_workOrder.total);
    },

    async res_workOrdersWeb(data) {
      if (this.timernum > 1) {
        //console.log('勿重复提交');
        return;
      }
      let res_workOrder = await api.workOrder(data);
      //console.log("工作台订单列表：", res_workOrder);
      this.list = res_workOrder.data;

      this.list = this.list.map((item) => {
        let newzuo = item.takeSp;
        let newxie = item.xiezuo;
        let newxiezuo =
          newzuo +
          (newzuo !== "" ? "/" : "" || newxie !== "" ? "/" : "") +
          newxie;
        return {
          ...item,
          newxiezuo: newxiezuo,
        };
      });
      this.num0 = res_workOrder.num0;
      this.num1 = res_workOrder.num1;
      this.num2 = res_workOrder.num2;
      this.num3 = res_workOrder.num3;
      this.num4 = res_workOrder.num4;
      this.num5 = res_workOrder.num5;
      this.num6 = res_workOrder.num6;
      this.num7 = res_workOrder.num7;
      this.num8 = res_workOrder.num8;
      this.num9 = res_workOrder.num9;
      this.num11 = res_workOrder.num11;
      this.num12 = res_workOrder.num12;
      this.num13 = res_workOrder.num13;
      this.totalpage = parseInt(res_workOrder.total);
    },

    async res_workOrdersSxWeb(data) {
      if (this.timernum2 > 1) {
        //console.log('勿重复提交');
        return;
      }
      let res_workOrder = await api.workOrderSx(data);
      // console.log("工作台订单列表：", res_workOrder);
      this.list = res_workOrder.data;

      this.list = this.list.map((item) => {
        let newzuo = item.takeSp;
        let newxie = item.xiezuo;
        let newxiezuo =
          newzuo +
          (newzuo !== "" ? "/" : "" || newxie !== "" ? "/" : "") +
          newxie;
        return {
          ...item,
          newxiezuo: newxiezuo,
        };
      });
      this.num0 = res_workOrder.num0;
      this.num1 = res_workOrder.num1;
      this.num2 = res_workOrder.num2;
      this.num3 = res_workOrder.num3;
      this.num4 = res_workOrder.num4;
      this.num5 = res_workOrder.num5;
      this.num6 = res_workOrder.num6;
      this.num7 = res_workOrder.num7;
      this.num8 = res_workOrder.num8;
      this.num9 = res_workOrder.num9;
      this.num11 = res_workOrder.num11;
      this.num12 = res_workOrder.num12;
      this.num13 = res_workOrder.num13;
      this.totalpage = parseInt(res_workOrder.total);
    },

    async getcity() {
      let res_ordertotal = await api.getcity();
      this.quyulist = res_ordertotal.data;
      let res1 = await api.gettechlist();
      this.techlist = res1.data;
      let res2 = await api.getsaleslista();
      this.saleslist = res2.data;
    },
    async res_ordertotal() {
      let res_ordertotal = await api.ordertotal();
      this.toptotal = res_ordertotal.data.总订单数;
      this.topshow1 = res_ordertotal.data.完成率;
      this.topshow2 = res_ordertotal.data.关闭率;
      this.topshow3 = res_ordertotal.data.利润率;
    },

    // 统计信息
    async res_Statistics(e) {
      let tongji = await api.Statistics(e);
      //console.log("统计信息：", tongji.data);
      this.alltotal = tongji.data.total;
      this.alltotal1 = tongji.data.total1;
      this.alltotal2 = tongji.data.total2;
      this.jstotal = tongji.data.total_js;
      this.jstotal1 = tongji.data.total1_js;
      this.jstotal2 = tongji.data.total2_js;
      this.jscompare = tongji.data.compare_js;
      this.yytotal = tongji.data.total_yy;
      this.yytotal1 = tongji.data.total1_yy;
      this.yytotal2 = tongji.data.total2_yy;
      this.yycompare = tongji.data.compare_yy;
      this.sltotal = tongji.data.total_sl;
      this.sltotal1 = tongji.data.total1_sl;
      this.sltotal2 = tongji.data.total2_sl;
      this.slcompare = tongji.data.compare_sl;
      this.qxtotal = tongji.data.total_cancel;
      this.qxtotal1 = tongji.data.total_qx1;
      this.qxtotal2 = tongji.data.total_qx2;
      this.qxtotal3 = tongji.data.total_qx3;
      this.qxtotalj = tongji.data.total_jiuyuan;
      this.qxcompare = tongji.data.compare_cancel;
      this.totalcompare = this.jscompare + this.yycompare + this.slcompare;

      this.jishipercentage = parseInt((this.jstotal1 / this.jstotal) * 100);
      this.yuyuepercentage = parseInt((this.yytotal1 / this.yytotal) * 100);
      this.shunlupercentage = parseInt((this.sltotal1 / this.sltotal) * 100);
      this.quxiaopercentage = parseInt((this.qxtotal1 / this.qxtotal) * 100);
      this.allpercentage = parseInt((this.alltotal1 / this.alltotal) * 100);
      this.showyj = tongji.showyj;
    },

    async res_StatisticsSelf(e) {
      let res = await api.StatisticsSelf(e);
      //console.log("统计信息：", tongji.data);
      this.a1 = res.data.a1;
      this.a2 = res.data.a2;
      this.a3 = res.data.a3;
      this.a4 = res.data.a4;
      this.b1 = res.data.b1;
      this.b2 = res.data.b2;
      this.b3 = res.data.b3;
      this.b4 = res.data.b4;
      this.c1 = res.data.c1;
      this.c2 = res.data.c2;
      this.c3 = res.data.c3;
      this.c4 = res.data.c4;
      this.d1 = res.data.d1;
      this.d2 = res.data.d2;
      this.d3 = res.data.d3;
      this.d4 = res.data.d4;
      this.e1 = res.data.e1;
      this.e2 = res.data.e2;
      this.f1 = res.data.f1;
      this.f2 = res.data.f2;
      this.g1 = res.data.g1;
      this.g2 = res.data.g2;
      this.g3 = res.data.g3;
      this.g4 = res.data.g4;
    },

    getInitial() {
      for (var i = 0; i < this.count; i++) {
        this.list.push(this.list[i]);
      }
    },

    checkbtn(e, btn) {
      this.rescueId = e;
      btn.classList.add("partcengji");
      this.audio++;
      if (this.audio == 3) {
        this.audio = 1;
      }
      this.look = 0;
    },
    partbtn(e, btn) {
      btn.classList.add("checkcengji");
      this.rescueId = e;
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
    },

    group(id) {
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
      this.rescueIdw = id;
      //console.log("-----中间调度id", id);
    },
    diaodu(id) {
      this.alters++;
      if (this.alters == 3) {
        this.alters = 1;
      }
      this.diaoduid = id;
      //console.log("-----中间调度id", id);
    },
    match(id) {
      this.ismatches++;
      if (this.ismatches == 3) {
        this.ismatches = 1;
      }
      this.matchid = id;
    },
    lish(id) {
      this.car++;
      if (this.car == 3) {
        this.car = 1;
      }
      this.rescueIdw = id;
      // this.car = !this.car;
    },
    use() {
      this.route = !this.route;
    },
    mouseenterFun(index) {
      ////console.log("悬浮----", index);
      this.yutishi = index;
    },
    mouseleaveFun() {
      this.yutishi = -1;
    },
    // 平台流转
    plat() {
      if (this.platshow == 0) {
        this.platshow = 1;
        this.datas.platshow = 1;
        this.res_workOrders(this.datas);
      } else {
        this.platshow = 0;
        this.datas.platshow = 0;
        this.res_workOrders(this.datas);
      }
    },
    // 拆分订单
    chai() {
      if (this.chaivalue) {
        this.isOpn = 1;
        this.datas.isOpen = 1;
        this.res_workOrders(this.datas);
      } else {
        this.isOpn = 0;
        this.datas.isOpen = 0;
        this.res_workOrders(this.datas);
      }
    },
    // 共享订单
    gongxiang() {
      if (this.xiangvalue) {
        this.isShare = 1;
        this.datas.isShare = 1;
        this.res_workOrders(this.datas);
      } else {
        this.isShare = 0;
        this.datas.isShare = 0;
        this.res_workOrders(this.datas);
      }
    },

    adds(e) {
      this.add = e;
      this.timernum = 0;
      this.isShow = e;
      this.datas.type = e;
      this.datas.page = 1;
      this.res_workOrders(this.datas);
    },

    newday(e) {
      //console.log("dianjil", e);
      this.newdaycolor = e;
      this.res_Statistics(e);
    },

    typedan(e) {
      (this.xuanzhong = false), (this.weixuan = true), (this.typedanclorl = e);
      //console.log("即时即时", e);
      this.datas.page = 1;
      this.datas.rescueStyle = e;
      this.res_workOrders(this.datas);
    },
    reset() {
      this.yincang = false;
      this.$refs.jiamiddle.classList.add("newmiddle");
      this.$refs.newmain.classList.add("newmian");
      this.$refs.newjinxing.classList.add("newjinxing");
    },
    reset2() {
      this.yincang = true;
      this.$refs.jiamiddle.classList.remove("newmiddle");
      this.$refs.newmain.classList.remove("newmian");
      this.$refs.newjinxing.classList.remove("newjinxing");
    },

    keyword1() {
      if(this.KeySearch){

          if(this.keytype == '' )
          {
            this.$message.warning('请选择搜索类型，进行关键词搜索');
            return false;
          }

          this.KeySearch = false;
          this.datas.page = 1;
          this.datas.keytype = this.keytype;
          this.datas.keyword = this.keyword;

          if(this.timeroutsearch != '')
            clearTimeout(this.timeroutsearch);

          let that = this;
          this.timeroutsearch = setTimeout(function () {
              that.res_workOrdersKeyWord(that.datas);
            }, 1000);
      }else{
          this.$message.warning('搜索结果未出，请勿频繁点击');
      }

    },

    // 时间排序
    paixu() {
      this.yinshengxu = false;
      this.yinjiangxu = true;
      this.list.sort(function (a, b) {
        return new Date(a.apTime).getTime() - new Date(b.apTime).getTime();
      });
    },
    paixu2() {
      this.yinshengxu = true;
      this.yinjiangxu = false;
      this.list.sort(function (a, b) {
        return new Date(b.apTime).getTime() - new Date(a.apTime).getTime();
      });
      // //console.log("排序后数组：", sortArry);
    },

    huifu() {
      this.xuanzhong = true;
      this.weixuan = false;

      this.typedanclorl = 0;
      this.rescueStyle = "";
      this.keytype = "";
      this.keyword = "";
      this.newchuang = "";
      this.newyue = "";
      this.add = 1;
      this.city = "";
      this.county = "";
      this.saleman = "";
      this.tech = "";
      this.value1 = new Date(new Date().setHours(0, 0, 0, 0));
      this.value2 = new Date(
        new Date().setHours(0, 0, 0, 0) + 3600 * 1000 * 24
      );
      this.datas.type = 1;
      this.datas.page = 1;
      this.datas.perpage = 100;
      this.datas.keytype = "";
      this.datas.keyword = "";
      this.datas.rescueStyle = "";
      this.datas.createEnd = "";
      this.datas.createStart = "";
      this.datas.yuyueEnd = "";
      this.datas.yuyueStart = "";
      this.datas.city = "";
      this.datas.county = "";
      this.datas.saleman = "";
      this.datas.tech = "";
      this.datas.platshow = 0;
      this.datas.isOpen = 0;
      this.datas.isShare = 0;
      this.res_workOrders(this.datas);
    },
    initWebSocket() {
      createWebSocket(this.global_callback);
    },
    websocketonopen() {},
    global_callback(msg) {
      console.log(
        "websocketwork的回调函数收到服务器信息：" + JSON.stringify(msg)
      );
      var cmds = msg.split(":");
      var kind = cmds.shift();
      var action;

      if (kind === "rescue") {
        if(this.$route.fullPath != '/')
        {
          return false;
        }


        action = cmds.shift();
        let that = this;
        if (
          action === "create" ||
          action === "accept" ||
          action === "arrive" ||
          action === "take" ||
          action === "dispatch" ||
          action === "finish" ||
          action === "huishou"||
          action === 'close'
        )
          if (this.datas.page > 1) {
            if (this.timernum2 == 0) {
              this.numclock2();
              this.res_workOrdersSxWeb(this.datas);
            } else {
              console.log("勿重复触发，请等待", 5 - this.timernum2);
            }
          } else {
            if (this.timernum == 0) {
              this.numclock();
              this.res_workOrdersWeb(this.datas);
            } else {
              console.log("勿重复触发，请等待", 5 - this.timernum);
            }
          }
      } else if (kind === "connection") {
        action = cmds.shift();
        if (action === "closed") {
          let that = this;
          let changecid = Cookies.get("ntwl-changecid");

          if (changecid == 1) {
            Cookies.set("ntwl-changecid", "");
          } else {
            setTimeout(function () {
              that.initWebSocket();
            }, 1000);
          }
        }
      }
    },
    timerclock() {
      if (this.timernum == 0 && this.timernum2 == 0) {
        this.res_workOrdersWeb(this.datas);
      }
      this.getUnusual();
    },
    numclock() {
      //console.log('当前：',this.timernum);
      if (this.timernum < 5) {
        this.timernum++;
        let that = this;
        setTimeout(function () {
          that.numclock();
        }, 1000);
      } else {
        this.timernum = 0;
      }
    },
    numclock2() {
      //console.log('当前2：',this.timernum2);
      if (this.timernum2 < 5) {
        this.timernum2++;
        let that = this;
        setTimeout(function () {
          that.numclock2();
        }, 1000);
      } else {
        this.timernum2 = 0;
      }
    },
    websocketonerror(e) {},
    //最新打电话
    call(tel, str) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
    sx() {},
    copyr(id) {
      this.copyrescueid = id;
      this.copyset++;
      if (this.copyset == 3) {
        this.copyset = 1;
      }

      //console.log(this.copyset);
    },
    async getcounty() {
      let res = await api.getCountyList(this.city);

      this.countylist = res.data;
    },
    // 自适应表格列宽
    flexColumnWidth(...args) {
      //console.log("接收参数", args);
      // 0:整个数组
      // 1:默认宽度
      // 2 && 之后:要排序的某个属性（可以多个）
      // flag为可选值，可不传该参数,传参时可选'max'或'equal',默认为'max'
      // flag为'max'则设置列宽适配该列中最长的内容,flag为'equal'则设置列宽适配该列中第一行内容的长度。
      var str;
      if (args && args.length) {
        if (args.length - 2 > 1) {
          // 多行，str数组
          [, , ...str] = [...args];
          //console.log("测试多行赋值：", str);
        } else {
          // 一行，str字符串
          [, , ...str] = [...args];
          str = str + "";
          //console.log("要排序的属性：", str);
        }
      } else {
        return;
      }
      var columnContent = "";
      var tableData = args[0];
      var flag = args[1] || "max";
      // 判断想要排序的属性是否非空，空直接return
      if (
        !tableData ||
        !tableData.length ||
        tableData.length === 0 ||
        tableData === undefined
      ) {
        return;
      }
      // 判断数组是否非空，空直接return
      if (!str || !str.length || str.length === 0 || str === undefined) {
        return;
      }
      if (flag === "equal") {
        // 获取该列中第一个不为空的数据(内容)
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i][str].length > 0) {
            //console.log("该列数据1[0]:", tableData[0][str]);
            columnContent = tableData[i][str];
            break;
          }
        }
      } else {
        if (Array.isArray(str)) {
          // 数组，多行
          var strArr = str.map((item, x, arr) => {
            let index = 0;
            for (let i = 0; i < tableData.length; i++) {
              if (tableData[i][item] === null) {
                return;
              }
              const now_temp = tableData[i][item] + "";
              const max_temp = tableData[index][item] + "";
              if (now_temp.length > max_temp.length) {
                index = i;
              }
            }
            return tableData[index][item];
          });

          strArr.sort((x, y) => {
            // 降序
            return y.length - x.length;
          });
          columnContent = strArr[0];
        } else {
          // 字符串，一行
          let index = 0;
          for (let i = 0; i < tableData.length; i++) {
            if (tableData[i][str] === null) {
              return;
            }
            const now_temp = tableData[i][str] + "";
            const max_temp = tableData[index][str] + "";
            if (now_temp.length > max_temp.length) {
              index = i;
            }
          }
          columnContent = tableData[index][str];
        }
        // 获取该列中最长的数据(内容)
      }
      //console.log("该列数据最长的内容:", columnContent);
      // 以下分配的单位长度可根据实际需求进行调整
      let flexWidth = 0;
      for (const char of columnContent) {
        if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
          // 如果是英文字符，为字符分配10个单位宽度
          flexWidth += 10;
        } else if (char >= "\u4e00" && char <= "\u9fa5") {
          // 如果是中文字符，为字符分配15个单位宽度
          flexWidth += 15;
        } else {
          // 其他种类字符，为字符分配10个单位宽度
          flexWidth += 15;
        }
      }
      if (flexWidth < 100) {
        // 设置最小宽度
        flexWidth = 100;
      }
      // if (flexWidth > 250) {
      //   // 设置最大宽度
      //   flexWidth = 250
      // }
      return flexWidth + "px";
    },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
  },
  watch: {
    async isShow(newVal) {
      //console.log(newVal);
    },
    getEmic(newval) {
      this.emicreg = newval;
    },
    newchuang(newval) {
      if (newval == "") {
        this.datas.page = 1;
        this.datas.createStart = "";
        this.datas.createEnd = "";
        this.res_workOrders(this.datas);
      } else {
        this.datas.page = 1;
        this.datas.createStart = this.newchuang[0];
        this.datas.createEnd = this.newchuang[1];
        this.res_workOrders(this.datas);
      }
    },
    newyue(newval) {
      if (newval == "") {
        this.datas.page = 1;
        this.datas.yuyueStart = "";
        this.datas.yuyueEnd = "";
        this.res_workOrders(this.datas);
      } else {
        this.rescueStyle = 2;
        this.typedanclorl = 2;
        this.datas.page = 1;
        this.datas.rescueStyle = 2;
        this.datas.yuyueStart = this.newyue[0];
        this.datas.yuyueEnd = this.newyue[1];
        this.res_workOrders(this.datas);
      }
    },
    city(newval) {
      if (newval == "") {
        this.datas.page = 1;
        this.datas.city = "";
        this.datas.county = "";
        this.res_workOrders(this.datas);
        this.county = "";
        this.countylist = [];
      } else {
        this.add = 0;
        this.datas.page = 1;
        this.datas.type = 0;
        this.datas.city = newval;
        this.datas.county = "";
        this.county = "";
        this.getcounty();
        this.res_workOrders(this.datas);
      }
    },
    county(newval) {
      if (newval == "" || newval == 0) {
        this.datas.page = 1;
        this.datas.county = "";
        this.res_workOrders(this.datas);
      } else {
        this.add = 0;
        this.datas.page = 1;
        this.datas.type = 0;
        this.datas.county = newval;
        this.res_workOrders(this.datas);
      }
    },
    value1(newval) {
      if (newval == "" || newval == null) {
        this.datas.page = 1;
        this.datas.createStart = "";
        this.res_workOrders(this.datas);
      } else {
        let a = Date.parse(newval.toString());
        this.datas.page = 1;
        this.datas.createStart = a / 1000;
        this.res_workOrders(this.datas);
      }
    },
    value2(newval) {
      if (newval == "" || newval == null) {
        this.datas.page = 1;
        this.datas.createEnd = "";
        this.res_workOrders(this.datas);
      } else {
        let a = Date.parse(newval.toString());
        this.datas.page = 1;
        this.datas.createEnd = a / 1000;
        this.res_workOrders(this.datas);
      }
    },
    saleman(newval) {
      if (newval == "") {
        this.datas.page = 1;
        this.datas.saleman = "";
        this.res_workOrders(this.datas);
      } else {
        this.add = 0;
        this.datas.page = 1;
        this.datas.type = 0;
        this.datas.saleman = newval;
        this.res_workOrders(this.datas);
      }
    },
    tech(newval) {
      if (newval == "") {
        this.datas.page = 1;
        this.datas.tech = "";
        this.res_workOrders(this.datas);
      } else {
        this.add = 0;
        this.datas.page = 1;
        this.datas.type = 0;
        this.datas.tech = newval;
        this.res_workOrders(this.datas);
      }
    },
    num0: {
      handler(newval, oldval) {
        //console.log(0, newval);
      },
      deep: true,
    },
    num1: {
      handler(newval, oldval) {
        //console.log(1, newval);
      },
      deep: true,
    },
    num2: {
      handler(newval, oldval) {
        //console.log(2, newval);
      },
      deep: true,
    },
    num3: {
      handler(newval, oldval) {
        //console.log(3, newval);
      },
      deep: true,
    },
    num4: {
      handler(newval, oldval) {
        //console.log(4, newval);
      },
      deep: true,
    },
    num5: {
      handler(newval, oldval) {
        //console.log(5, newval);
      },
      deep: true,
    },
    num6: {
      handler(newval, oldval) {
        //console.log(6, newval);
      },
      deep: true,
    },
    num7: {
      handler(newval, oldval) {
        //console.log(7, newval);
      },
      deep: true,
    },
    num8: {
      handler(newval, oldval) {
        //console.log(8, newval);
      },
      deep: true,
    },
    num9: {
      handler(newval, oldval) {
        //console.log(9, newval);
      },
      deep: true,
    },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getLook() {
      return this.$store.state.lookonly;
    },
  },
  mounted() {
    let worktable2234 =  this.$refs.newjinxing.querySelector(".el-scrollbar__wrap");
    worktable2234.addEventListener(
      "scroll",
      () => {
        // 防抖,默认200ms3
        if (this.timer) clearTimeout(this.timer); //清除这个定时器
        this.timer = setTimeout(async () => {
          let x = 10; // 滚动条快到底的距离
          // true,滚到底了
          if (
            worktable2234.offsetHeight + worktable2234.scrollTop >=
            worktable2234.scrollHeight - x
          ) {

            if (this.isChange && this.list.length < this.totalpage) {
              this.isChange = false;
              this.datas.page++;
              // 发送新的页码请求
              let res_workOrder = await api.workOrder(this.datas);
              res_workOrder = res_workOrder.data;

              res_workOrder = res_workOrder.map((item) => {
                let newzuo = item.takeSp;
                let newxie = item.xiezuo;
                let newxiezuo =
                  newzuo +
                  (newzuo !== "" ? "/" : "" || newxie !== "" ? "/" : "") +
                  newxie;
                return {
                  ...item,
                  newxiezuo: newxiezuo,
                };
              });

              this.list = [...this.list, ...res_workOrder];
              this.isChange = true;
            } else {
              //this.$message.warning("没有更多了");
            }
          }
        }, 1000);
      },
      true
    );

    let changecid = Cookies.get("ntwl-changecid");

    if (changecid == 1) {
      console.log("挂载前 重新拉一次");
    }
    this.yewuchart();
  },
  beforeMount() {
    // 在页面挂载前就发起请求
    this.getInitial();
  },
};
</script>

<style lang="less" scoped>
.zhuang {
  width: 80px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  float: left;
  line-height: 32px;
  color: #fff;
  margin-top: 3px;
  box-sizing: border-box;
}
.jinXing {
  // width: 1170px;
  height: 500px;
  padding: 0 30px;
  box-sizing: border-box;
}

.jinXing /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  color: #000;
  height: 30px;
}

.jinXing /deep/ .el-table__inner-wrapper {
  width: 100%;
  z-index: 0;
}

.jinXing /deep/ .el-table-fixed-column--right:last-of-type {
  width: 310px;
  display: flex;
  align-items: center;
  height: 50px !important;
}
.jinXing
  /deep/
  .el-table--enable-row-transition
  .el-table__body
  td.el-table__cell {
  height: 70px !important;
}

.jinXing /deep/ .el-table__row {
  height: 70px !important;
  box-sizing: border-box;
}
.jinXing /deep/ .el-table__cell {
  padding: 0;
}
.jinXing /deep/ .el-button--small {
  width: 60px !important;
  margin-left: 10px;
}
.jinXing /deep/ .el-button + .el-button {
  margin: 0;
  margin-left: 10px;
}
.jinXing /deep/ .is-vertical {
  width: 15px !important;
}
.jinXing /deep/ .is-horizontal {
  height: 15px !important;
}

.taitu {
  display: flex;
  width: 130px;
  cursor: pointer;
}

table {
  width: 1170px;
  padding-left: 30px;
  font-size: 14px;
}
thead {
  height: 30px;
  background-color: #f8fafc;
}
.tai {
  width: 100px;
  padding-left: 30px;
}
.num {
  width: 150px;
  padding-right: 40px;
}
.carDian {
  width: 250px;
  padding-right: 120px;
}
.zuo {
  padding-right: 65px;
}
// 工作台列表新
.tdNum {
  padding-right: 30px;
}

th {
  // width: 190px;
  box-sizing: border-box;
  text-align: left;
}
tr {
  height: 70px !important;
}

tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}
.bg_color2 {
  background-color: #fafafa !important;
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.apply {
  width: 34px;
  height: 34px;
  position: relative;
  top: -37px;
  right: -420px;
  cursor: pointer;
}
.box-main {
  width: 1730px;
  height: 840px;
  margin-left: 190px;
  background-color: #f7f9fb;
}
.middle {
  width: 1670px;
  // width: 1315px;
  height: 840px;
  margin-left: 30px;
  float: left;
  //   background-color: skyblue;
}
.newmiddle {
  width: 1670px;
  height: 100%;
  float: left;
}
.newjinxing {
  height: 700px !important;
}
.newmian {
  height: 95% !important;
}
.right-box {
  float: right;
  width: 350px;
  height: 840px;
  margin-left: 30px;
  //   background-color: darkgrey;
}
// 进度
.plan-ul {
  display: flex;
  list-style: none;
  height: 107px;
  box-sizing: border-box;
  margin-top: 20px;
}
.newjindu {
  display: flex;
  width: 100%;
  height: 180px;
  // background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  justify-content: space-between;
}
.newjinduleft {
  width: 1316px;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 15px 30px;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}
.newjinduright {
  width: 320px;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 30px;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.allnav {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.allimg {
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
.allmain {
  width: 100%;
  height: 105px;
  border-radius: 8px;
  background-image: url("../assets/img/index/allbei.png");
  background-size: 100% 100%;
  margin-top: 10px;
  padding: 14px 30px;
  box-sizing: border-box;
}
.allmaintit,
.allmainbottom {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  color: #fff;
}
.allmainbottom {
  margin-top: 7px;
}
.allmaintit {
  margin-right: 20px;
}
.allnum {
  font-size: 32px;
  font-weight: bold;
  margin: 0 10px;
}
.allcheng {
  font-size: 18px;
  font-weight: bold;
  margin: 0 10px;
}
.newleft {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newtit,
.activenewtit {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.newtit {
  border-bottom: 4px solid #e0e0e0;
}
.newtit:hover {
  color: #2c68ff;
  border-bottom: 4px solid #2c68ff;
}
.activenewtit {
  color: #2c68ff;
  border-bottom: 4px solid #2c68ff;
}
.newzi {
  width: 52px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background-color: #f7f8fa;
  border-radius: 8px;
  color: #727e96;
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
}
.newzi:hover {
  background-color: #edf2ff;
  color: #2c68ff;
}
.newxuanzi {
  width: 52px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 8px;
  background-color: #edf2ff;
  color: #2c68ff;
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
}
.newxuan {
  // border: 1px solid #e2e2e5;
  height: 100%;
  display: flex;
  align-items: center;
}
.newleftmain {
  width: 100%;
  height: 108px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newjishi {
  width: 260px;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid #edeff3;
}
.jishinav {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newjishitit {
  font-size: 14px;
  font-weight: 400;
}
.newnum,
.newnum2 {
  font-size: 14px;
  font-weight: 700;
}
.newnum {
  color: #1ad092;
}
.newnum2 {
  color: #ff202a;
}
.shengimg {
  width: 20px;
  height: 20px;
}
.jishinum {
  display: flex;
  align-items: center;
}
.jishimain {
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
}
.jishimainimg {
  width: 40px;
  height: 40px;
}
.dannum {
  margin-left: 15px;
  font-size: 12px;
  color: #b6b6b6;
}
.danshu {
  font-size: 28px;
  color: #333333;
}
.grjishibottom {
  width: 100%;
  height: 18px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  color: #666666;
}
.jishibottom {
  width: 100%;
  height: 18px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  color: #666666;
}
.completenum {
  color: #05bb7d;
  font-weight: 700;
}
.completeno {
  color: #fa1515;
  font-weight: 700;
}

.plan {
  float: left;
  width: 210px;
  height: 107px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.16);
  opacity: 1;
  padding: 20px;
  box-sizing: border-box;
  margin-right: 30px;
  border-radius: 8px;
  background-color: #fff;
}
.jindu {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.xinNum {
  display: flex;
}
.style {
  background: linear-gradient(180deg, #e2efff 0%, #ffffff 100%);
}
.five {
  margin: 0;
}
.p3 {
  width: 170px;
  height: 8px;
  background: #eef1f3;
  opacity: 1;
  border-radius: 30px;
  margin: 0;
  // margin-top: 25px;
}
.shuNum {
  margin-bottom: 10px;
}
.taibox {
  display: flex;
  align-items: center;
}
.dingnum {
  font-size: 24px;
}
.s1 {
  display: inline-block;
  width: 150px;
  height: 8px;
  background: linear-gradient(270deg, #2c68ff 0%, #4a9fff 100%);
  opacity: 1;
  border-radius: 20px;
  margin-bottom: 9px;
}
.main {
  // width: 1170px;
  width: 100%;
  height: 610px;
  // height: 675px;
  // background-color: orange;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.16);
  opacity: 1;
  border-radius: 8px;
  position: relative;
}
.main-nav {
  width: 100%;
  // height: 105px;
  overflow: hidden;
  // background-color: skyblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 30px;
  box-sizing: border-box;
  // background-color: pink;
}
.newhead {
  width: 100%;
  // height: 35px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 10px;
}
.newheadbom {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
  margin-top: 10px;
}
.newheadbom /deep/ .el-form-item--default {
  margin-right: 20px;
}
.newheadbom /deep/ .el-form {
  height: 30px !important;
}
.newsou {
  display: flex;
  align-items: center;
}
.newtime {
  display: flex;
}
.newtime /deep/ .el-input--prefix .el-input__inner {
  margin-right: 10px;
}
.newbtn,
.newbtn2 {
  // width: 60px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 20px;
}
.newbtn {
  background-color: #2c68ff;
}
.newbtn2 {
  background-color: #bcbcbc;
}
.dabei {
  background-color: #bcbcbc;
  cursor: pointer;
}
.newzhi {
  width: 15px;
  height: 15px;
  //margin-right: 5px;
}
.reset1,
.reset2 {
  font-size: 12px;
  border: 0;
  cursor: pointer;
  color: #fff;
  outline: 0;
}
.reset1 {
  background-color: #2c68ff;
}
.reset2 {
  background-color: #bcbcbc;
}
// .reset:hover {
//   color: #40bcff;
//   border: 1px solid #40bcff;
// }
.state li a {
  width: 72px;
  height: 72px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: #333333;
  opacity: 1;
  float: left;
  cursor: pointer;
  text-decoration: none;
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f8fa;
}
.state li a:hover {
  color: #2c68ff;
}
.state {
  display: flex;
  align-items: center;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.newtabnum {
  font-size: 20px;
  font-weight: bold;
}
.newactive {
  font-size: 20px;
  font-weight: bold;
  color: #b5b5be;
}
.active {
  color: #2c68ff !important;
  background-color: #edf2ff !important;
  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 4px;
}
.activenum {
  color: #2c68ff !important;
}
.newheadnum {
  color: #9ea3b9;
}

.time-box {
  // width: 400px;
  height: 40px;
  // margin-left: 697px;
  margin-top: 15px;
  // background-color: greenyellow;
  display: flex;
  align-items: center;
}
.time {
  float: left;
  // width: 160px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  padding: 0 10px;
  opacity: 1;
  border-radius: 2px;
  text-align: center;
  line-height: 40px;
  // margin-right: 20px;
  box-sizing: border-box;
  // background-color: orangered;
}
.seek {
  float: left;
  // width: 200px;
  // height: 40px;
  background: #ffffff;
  // border: 1px solid #dadfe6;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  display: flex;
}
.sekint {
  float: left;
  width: 156px;
  height: 37px;
  padding: 15px;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #dadfe6;
  border-left: 0;
}
.glass {
  // float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  margin-top: -1px;
  background: #f7f9fb;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: 2px;
  right: 1px;
  cursor: pointer;
}
.icon {
  width: 14px;
  height: 14px;
  // margin-left: 13px;
  // margin-top: 13px;
  cursor: pointer;
}
.time i {
  font-style: normal;
}
.time i:hover {
  color: #2c68ff;
}
.day,
.week,
.xing {
  font-size: 14px;
  cursor: pointer;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  // margin-right: 10px;
  color: #727e96;
  opacity: 1;
}
.hue {
  color: #2c68ff;
}
.tiao {
  display: inline-block;
  width: 0px;
  height: 14px;
  // margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
  margin: 0 5px;
}
.tiao1 {
  display: inline-block;
  width: 0px;
  height: 14px;
  margin-right: 10px;
  margin-left: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}

// 进行中
.march {
  list-style: none;
  height: 450px;
  overflow: hidden;
}
.march::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.march-box {
  float: left;
}
.handle {
  float: right;
  margin-right: 34px;
  // margin-top: 30px;
  line-height: 70px;
}
.handle i {
  font-style: normal;
}
.march li {
  cursor: pointer;
  height: 70px;
  border-bottom: 1px solid #f7f9fb;
  box-sizing: border-box;
}
.march li:hover {
  background-color: #f9fafe !important;
}
.li-img {
  float: left;
  width: 40px;
  height: 40px;
  // margin-top: 23px;
  // padding-left: 30px;
}
.tatai {
  width: 80px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  float: left;
  line-height: 32px;
  margin-top: 18px;
  color: white;
}
.p1-span,
.p1-span1 {
  width: 537px;
  height: 21px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  opacity: 1;
}
.p1-span {
  color: #2c68ff;
}
.p1-span1 {
  color: #2a3346;
}
.p1-span2 {
  width: 79px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
}
.p2 {
  width: 337px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
  margin-left: 10px;
  margin-top: -15px;
}
i {
  font-style: normal;
}
.abolish,
.refromed {
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2c68ff;
  opacity: 1;
  cursor: pointer;
}
// .abolish:hover{
//   color: #0212be;
// }
// .refromed:hover{
//   color: red;
// }
.jinXing-sorter {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: -10px;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}

.take,
.take1,
.take2,
.take3 {
  display: inline-block;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  box-sizing: border-box;
  background: #e8f3fe;
  opacity: 1;
  border-radius: 2px;
  color: #2c68ff;
  vertical-align: top;
  padding: 0 5px;
}

.switch {
  display: flex;
}
.pingtai {
  width: 130px;
  font-size: 14px;
  color: #727e96;
}

.jishistyle,
.shunlustyle,
.yuyuestyle,
.jishistyle2,
.shunlustyle2,
.yuyuestyle2 {
  display: inline-block;
  // width: 60px;
  height: 25px;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  border-radius: 4px;
  vertical-align: top;
  padding: 0 5px;
  cursor: pointer;
}
.take1 {
  color: #2c68ff;
  background: #e8f3fe;
}
.take2 {
  color: #27cc8b;
  background: #eefbf4;
}
.jishistyle,
.yuyuestyle,
.shunlustyle {
  color: #666;
  background-color: #ededed;
  margin-right: 10px;
}
.jishistyle2,
.yuyuestyle2,
.shunlustyle2 {
  color: #fff;
  background-color: #2c68ff;
  margin-right: 10px;
}

.take3 {
  color: #fff6e9;
  background: #ff9100;
}

.danbox {
  display: flex;
  // align-items: center;
  flex-direction: column;
}
.danboxleft {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.seek /deep/ .el-select .el-input__inner {
  width: 100px;
  border-radius: 0;
  margin-left: 20px;
}
.newsou /deep/ .el-select .el-input__inner {
  width: 100px;
  border-radius: 0;
  margin-left: 20px;
}
.newsou /deep/ .el-select .el-input .el-select__caret.el-icon {
  z-index: 0;
}

.yuyuetime {
  color: #27cc8b;
  cursor: pointer;
}

.maketit {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #6d6262;
  padding: 2px 5px;
  box-sizing: border-box;
  color: #fff;
}
.paixustyle {
  cursor: pointer;
}

.splitimg {
  width: 16px;
  height: 16px;
  margin-top: 8px;
  margin-left: 5px;
}

.jinXing /deep/ .el-table td.el-table__cell div {
  //display: flex;
  align-items: center;
}
.grimmediate,
.grimmediate2,
.grimmediate3,
.grimmediate4,
.grimmediate5,
.grimmediate6,
.grimmediate7 {
  width: 170px;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}
.grimmediate {
  background-color: #4076ff;
}
.grimmediate2 {
  background-color: #727e96;
}
.grimmediate3 {
  background-color: #ff950a;
}
.grimmediate4 {
  background-color: #29cc8c;
}
.grimmediate5 {
  background-color: #ff525c;
}
.grimmediate6 {
  background-color: #4076ff;
}
.grimmediate7 {
  background-color: #789eff;
}

.grbeimg {
  // width: 120px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
}
.grimmediatetxt,
.grimmediatetxt2,
.grimmediatetxt3,
.grimmediatetxt4,
.grimmediatetxt5,
.grimmediatetxt6,
.grimmediatetxt7 {
  width: 74px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 8px 0px 20px;
  font-size: 14px;
  background-color: #edf2ff;
}
.grimmediatetxt {
  color: #4076ff;
}
.grimmediatetxt2 {
  color: #727e96;
}
.grimmediatetxt3 {
  background-color: #faf3e9;
  color: #ff950a;
}
.grimmediatetxt4 {
  background-color: #e8faf3;
  color: #29cc8c;
}
.grimmediatetxt5 {
  background-color: #ffedee;
  color: #ff525c;
}

.grimmediatetxt6 {
  background-color: #edf2ff;
  color: #4076ff;
}
.grimmediatetxt7 {
  background-color: #f0f4ff;
  color: #789eff;
}
.grimmediatenum {
  font-size: 32px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.grimmediateadd {
  font-size: 10px;
  margin-left: 10px;
}
.grimmediatetit {
  font-size: 12px;
  color: #fff;
}

.immediate,
.immediate1,
.immediate2,
.immediate3 {
  width: 220px;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}
.immediate4 {
  width: 220px;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}
.immediate {
  background-color: #4076ff;
}
.immediate1 {
  background-color: #29cc8c;
}
.immediate2 {
  background-color: #ff950a;
}
.immediate3 {
  background-color: #727e96;
}
.immediate4 {
  background-color: #ff525c;
}
.immediate /deep/ .el-progress-bar__outer {
  background-color: #8dadff;
}
.grimmediate /deep/ .el-progress-bar__outer {
  background-color: #8dadff;
}
.immediate1 /deep/ .el-progress-bar__outer {
  background-color: #7fe1ba;
}
.grimmediate4 /deep/ .el-progress-bar__outer {
  background-color: #7fe1ba;
}
.immediate2 /deep/ .el-progress-bar__outer {
  background-color: #ffc06c;
}
.grimmediate3 /deep/ .el-progress-bar__outer {
  background-color: #ffc06c;
}
.immediate3 /deep/ .el-progress-bar__outer {
  background-color: #abb2c0;
}
.grimmediate2 /deep/ .el-progress-bar__outer {
  background-color: #abb2c0;
}
.immediate4 /deep/ .el-progress-bar__outer {
  background-color: #ff979d;
}
.grimmediate5 /deep/ .el-progress-bar__outer {
  background-color: #ff979d;
}

.grimmediate6 /deep/ .el-progress-bar__outer {
  background-color: #9db8ff;
}
.grimmediate7 /deep/ .el-progress-bar__outer {
  background-color: #aec5ff;
}

.immediatetxt,
.immediatetxt1,
.immediatetxt2,
.immediatetxt3,
.immediatetxt4 {
  width: 74px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 8px 0px 20px;
  font-size: 14px;
}
.immediatetxt {
  background-color: #edf2ff;
  color: #4076ff;
}
.immediatetxt1 {
  background-color: #e8faf3;
  color: #29cc8c;
}
.immediatetxt2 {
  background-color: #faf3e9;
  color: #ff950a;
}
.immediatetxt3 {
  background-color: #edf1f5;
  color: #727e96;
}
.immediatetxt4 {
  background-color: #ffedee;
  color: #ff525c;
}
.immediatenum {
  font-size: 36px;
  color: #fff;
  display: flex;
  align-items: center;
}
.immediateadd {
  font-size: 14px;
  margin-left: 10px;
}
.immediatexian {
  width: 180px;
  height: 4px;
  border-radius: 2px;
  background-color: #e0e0e0;
}
.immediatetit {
  font-size: 12px;
  color: #fff;
}

.zongnum {
  // font-style: normal;
  // width: 44px;
  // height: 24px;
  text-align: center;
  // line-height: 24px;
  color: #fff;
  font-weight: bold;
  background-color: #ff505a;
  border-radius: 12px;
  padding: 2px 12px;
  box-sizing: border-box;
}
.allnavleft {
  display: flex;
  align-items: center;
}
.totalorder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.totalorder:after {
  content: "";
  flex: auto;
}
.totalorderblock {
  width: 46px;
  height: 60px;
  font-size: 20px;
  color: #727e96;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 15px;
  box-sizing: border-box;
  cursor: pointer;
}
.totalordertit {
  font-size: 14px;
}
.totalordernum {
  color: #b5b5be;
}

.totalorderactive {
  color: #2a3346;
}
.beimg {
  // width: 120px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
}
.yewubox /deep/ .el-table--border .el-table__cell {
  text-align: center;
}
.yewubox /deep/ .el-table .pink {
  background-color: pink;
}
.yewutab {
  height: 410px;
}
.yewubox /deep/ .el-table__row:last-of-type{
  position: sticky;
  bottom: 0;
  background-color: rgba(245, 247, 250, 1);
  z-index: 10;
}
</style>

<style>
.partcengji {
  z-index: 30 !important;
}
.checkcengji {
  z-index: 20 !important;
}
.yewubox {
  width: 1316px;
  /* max-height: 600px; */
  /* overflow-y: scroll; */
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 42px;
  z-index: 10;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
}
.yewubiao {
  width: 1300px;
  height: 200px;
}

.yewubox::before {
  content: "";
  display: inline-block;
  width: 0px;
  height: 0px;
  position: absolute;
  left: 270px;
  top: -16px;
  border: 9px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
}

.yewumain {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.yewutit {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}
.yetit {
  color: #29cc8c;
}
.yetit2 {
  color: #000;
}
.yetxt {
  color: red;
}
.yewutxt {
  font-size: 16px;
  color: red;
}
</style>

